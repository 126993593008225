import * as React from "react";
import { Form, Link, type MetaFunction } from "@remix-run/react";
import { Button } from "#app/components/ui/button.tsx";
// import { Input } from '#app/components/ui/input.tsx'
// import { Label } from '#app/components/ui/label.tsx'
import { cn } from "#app/utils/misc.ts";
import { SquareStackIcon } from "lucide-react";
import { GeneralErrorBoundary } from "#app/components/error-boundary.tsx";

export const meta: MetaFunction = () => {
  return [{ title: "Login to JPGApps" }];
};
export default function LoginRoute() {
  return (
    <div className="container relative grid h-[100vh] min-h-full flex-col items-center justify-center lg:max-w-none lg:grid-cols-2 lg:px-0">
      <div className="relative hidden h-full flex-col bg-muted p-10 text-white dark:border-r lg:flex">
        <div className="absolute inset-0 bg-zinc-900" />
        <div className="relative z-20 flex items-center text-4xl font-medium">
          <SquareStackIcon className="mr-2 h-12 w-12" />
          JPGApps
        </div>
        <div className="relative z-20 mt-auto">
          <blockquote className="space-y-2">
            <p className="text-lg">
              &ldquo;Farmers everywhere provide bread for all humanity, but it
              is Christ alone who is the bread of life...Even if all the
              physical hunger of the world were satisfied, even if everyone who
              is hungry were fed by his or her own labor or by the generosity of
              others, the deepest hunger of man would still exist...Therefore, I
              say, Come, all of you, to Christ. He is the bread of life. Come to
              Christ and you will never be hungry again.&rdquo;
            </p>
            <footer className="text-right text-sm">
              St. Pope John Paul II
            </footer>
          </blockquote>
        </div>
      </div>
      <div className="lg:p-8">
        <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
          <div className="flex flex-col space-y-2 text-center">
            <div className="relative z-20 flex items-center justify-center text-3xl font-medium lg:hidden">
              <SquareStackIcon className="mr-2 h-10 w-10" />
              JPGApps
            </div>
            <h1 className="text-2xl font-semibold tracking-tight">
              Log in to your account
            </h1>
            <p className="text-sm text-muted-foreground">Sign in with Google</p>
          </div>
          <UserAuthForm />
          <p className="px-8 text-center text-sm text-muted-foreground">
            By clicking continue, you agree to our{" "}
            <Link
              to="/tos"
              className="underline underline-offset-4 hover:text-primary"
            >
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link
              to="/privacy"
              className="underline underline-offset-4 hover:text-primary"
            >
              Privacy Policy
            </Link>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {
  redirectTo?: string;
  formError?: string | null;
}

export function UserAuthForm({
  redirectTo,
  formError,
  className,
  ...props
}: UserAuthFormProps) {
  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <Form action="/auth/google" method="POST" name="login">
        <Button variant="outline" size="lg" className="w-full" type="submit">
          Google
        </Button>
      </Form>
    </div>
  );
}

export function ErrorBoundary() {
  return <GeneralErrorBoundary />;
}
